<template>
  <div>
    <v-row
      ><v-col cols="12">
        <WeeklyReport />
      </v-col>
      <v-col cols="12">
        <UserSubjects path="classroom" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserSubjects from "@/components/UserSubjects";
import WeeklyReport from "@/components/WeeklyReport";
export default {
  name: "Classroom",
  components: {
    UserSubjects,
    WeeklyReport,
  },
  async mounted() {
    await this.$store.dispatch('authModule/getUser');
    const user = this.$store.getters['authModule/getUser'];

    if (user) {
      const role = user.role;
      switch (role) {
        case 'teacher':
          this.$router.push("/workplace");
          break;
        case 'student':
          await this.$store.dispatch("authModule/getWeeklyReport");
          break;
        default:
          this.$router.push("/main");
          break;
      }
    } else {
      this.$router.push("/main");
    }
  },
};
</script>

<style></style>
